import { Workbox } from 'workbox-window';

const wb = new Workbox('/service-worker.js');

export const unregister = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
                registration.unregister();
            });
        });
        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                caches.delete(cacheName);
            });
        });
        console.log('Service Worker desregistrado com sucesso.');
    }
};

export const register = () => {
    if ('serviceWorker' in navigator) {
        wb.register()
            .then((registration) => {
                console.log('Service Worker registrado com sucessoo');
                registration.onupdatefound = () => {
                    const newWorker = registration.installing;
                    newWorker.onstatechange = () => {
                        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                            registration.update().then(() => {
                                console.warn('Uma nova versão do Service Worker foi carregadaa.');
                                newWorker.postMessage({ type: 'SKIP_WAITING' });
                            });
                        }
                    };
                };

                const checkForConfigUpdates = async () => {
                    try {
                        const response = await fetch('/config/config.json');
                        const configData = await response.json();
                        console.log('/config/config.json: ', configData);
                        const prevConfigVersion = localStorage.getItem('configVersion');
                        const currentConfigVersion = JSON.stringify(configData);

                        if (prevConfigVersion && prevConfigVersion !== currentConfigVersion) {
                            console.log('O config.json foi atualizado. Atualizando o Service Worker...');

                            registration.update().then(async () => {
                                console.warn('Uma nova versão do Service Worker foi carregada.');
                                await registration.unregister();
                            });
                        }

                        localStorage.setItem('configVersion', currentConfigVersion);
                    } catch (error) {
                        console.error('Erro ao buscar o config.json:', error);
                    }
                };

                setTimeout(checkForConfigUpdates, 10000);
            })
            .catch((error) => {
                console.error('Falha ao registrar o Service Worker:', error);
            });
    } else {
        console.log('Não foi possível criar um serviceWorker.');
    }
};
