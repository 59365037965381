module.exports = {
  acompanhante: 'Acompanhante',
  acompanhanteDoador: 'Acompanhante/doador',
  acompanhantes: 'Acompanhantes',
  administrativo: 'Administrativo',
  agendamentoDocumentacao: 'Agendamento/documentação',
  altaHospitalar: 'Alta hospitalar',
  anexarPassagem: 'Anexar comprovante de passagem',
  anexosAcompanhantes: 'Anexos de justificativa para troca de acompanhantes',
  anexosAgendamentos: 'Anexos de agendamentos',
  anexosAgendamentos: 'Anexo(s) do agendamento',
  anexosArquivamento: 'Anexos de arquivamento',
  anexosCancelamento: 'Anexos do cancelamento',
  anexosComprovante: 'Anexos do comprovante',
  anexosDevolucao: 'Anexos de devolução',
  anexosEstadual: 'Anexos da alteração pelo TFD-Estadual',
  anexosGerais: 'Anexos gerais do processo',
  anexosJustificativa: 'Anexo(s) da justificativa',
  anexosPassagens: 'Anexos de passagens',
  anexosTransporte: 'Anexos de justificativa para troca de transporte',
  anoAberturaAutos: 'Ano de abertura dos autos',
  arquivar: 'Arquivar',
  atencaoManualTfd: 'Atenção para o item 2.13 do manual do TFD',
  aviso1: 'Devido ao fechamento do Exercício ',
  aviso2: ', as solicitações de passagem devem seguir a regra abaixo:',
  aviso3: ' - AGENDAMENTOS de 15/01/',
  aviso4: ' em diante deverão ser inseridos no sistema a partir de 01/01/',
  aviso5: ' Observação: a partir de 01/01/',
  aviso6: ', o sistema será liberado para todos agendamentos EXERCICIO ',
  aviso7: ', obedecendo sempre os prazos para solicitações contidos no Manual do TFD.',
  avisoAmbulancia: 'Em caso de transporte convencional, como carro comum é necessário justificar. Caso for preciso, clique no botão "Alterar" para habilitar a adição da justificativa e anexos. Obs.: Caso este tipo de transporte esteja indisponível será utilizado o transporte original.',
  avisoCancelarAlterarTransporte: 'Tem certeza que deseja cancelar a alteração de transporte? Justificativas e anexos serão excluídos.',
  avisoDataPosterior: 'A data de ida é posterior a data de agendamento. Por favor, revise esses dados para certificar que os mesmos estão corretos antes de prosseguir com a solicitação.',
  avisoOnibusLeito: 'Ônibus leito somente com justificativa no laudo ou justificativa médica, para isto é necessário justificar. Caso for preciso, clique no botão "Alterar" para habilitar a adição da justificativa e anexos.',
  avisoTransporteModificado: 'Esta solicitação teve o seu meio de transporte original modificado.',
  avisoTrocaAcompanhante: 'Salvo exceções (Ex. Brasília, Belo Horizonte, Goiânia, devido a distância) as trocas de acompanhante devem ser de ônibus. Essa troca deve ser amparada por documentação da Unidade Prestadora.',
  bpa: 'BPA',
  cadastroMotivoCancelamento: 'Cadastro de motivo de cancelamento',
  cadastroMotorista: 'Cadastro de motorista',
  cancelamento: 'Cancelamento',
  carimbo: 'Protocolos (carimbo do tempo)',
  cepInvalido: 'CEP inválido',
  cepNaoExiste: 'O CEP digitado não existe. Por favor, tente novamente.',
  cidadeInvalida: 'Município inválido',
  complementoInvalido: 'Complemento inválido',
  complementoParentesco: 'Complemento relação com o paciente',
  comprovante: 'Comprovante',
  comprovantes: 'Comprovantes de comparecimento',
  confirmacaoCancelarAlterar: 'Tem certeza que deseja cancelar a alteração de acompanhantes? Justificativas e anexos serão excluídos.',
  confirmarSairEdicao: 'Você tem certeza que deseja sair da edição da solicitação? Todas as modificações não salvas serão perdidas.',
  contatoSetor: 'Contato do setor solicitante',
  criarEspelho: 'Criar processo espelho',
  dadosAdicionais: 'Dados adicionais',
  dadosAlteracaoEstadual: 'Dados da alteração estadual',
  dadosFatura: 'Dados da fatura',
  dadosFuncionarios: 'Dados de funcionários',
  dadosGerais: 'Dados gerais',
  dadosGeraisProcesso: 'Dados gerais do processo',
  dadosPaciente: 'Dados do paciente',
  dataAgendamento: 'Data do agendamento',
  dataAgendamentoInvalida: 'Data de agendamento inválida',
  dataCompra: 'Data da compra',
  dataEmissaoAgendamento: 'Data de emissão do agendamento',
  dataIda: 'Data de ida',
  dataRetorno: 'Data de retorno',
  dataSolicitacao: 'Data de solicitação',
  destino: 'Destino',
  devolucao: 'Devolução',
  devolver: 'Devolver',
  dias: 'DIA(S)',
  diferencaDatas: 'Diferença entre data de solicitação e emissão',
  doador: 'Doador',
  dossiePaciente: 'Dossiê do paciente',
  dossieInvalido: 'O dossiê deve ter pelo menos 10 caracteres',
  ehDoador: 'Doador?',
  ehReembolso: 'Reembolso?',
  encaminhar: 'Encaminhar',
  encaminharRegulador: 'Encaminhar para aprovação do regulador',
  encaminharViagem: 'Encaminhar solicitação de viagem',
  erroBuscaCep: 'Não foi possível buscar informações pelo CEP. Por favor, tente novamente mais tarde.',
  erroCarregarProcesso: 'Não foi possível carregar o processo para alteração. Tente novamente mais tarde',
  erroDataAgendamentoLaudoVencido: 'Essa solicitação de viagem se realizará após o vencimento do laudo. Favor providenciar a renovação do mesmo.',
  erroIdade60Anos: 'Não são recomendados acompanhantes que se enquadrem nos grupos de risco (maiores de 60 anos, obesos, hipertensos, diabéticos, com imunidade baixa ou com doenças pulmonares ou cardíacas).',
  erroIdadeAcompanhante: "Os acompanhantes deverão ser maiores de 18 (dezoito) anos documentados e capacitados física/mentalmente. O acompanhante deverá ser preferencialmente pessoa da família, maior de idade e responsável legal, não podendo o mesmo residir no município de destino.",
  erroSalvarMotivoCancelamento: 'Não foi possível salvar os dados do motivo de cancelamento.',
  erroSalvarMotorista: 'Não foi possível salvar os dados do motorista.',
  exigeComplemento: 'Exige complemento textual?',
  fatura: 'Fatura',
  formatosArquivo: 'Arquivo (.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx)',
  funcionarioAutorizador: 'Funcionário autorizador',
  funcionarioComprovante: 'Funcionário responsável pelo comprovante',
  funcionarioPreenchedor: 'Funcionário preenchedor',
  funcionarioRegulador: 'Funcionário regulador',
  funcionarioSolicitante: 'Funcionário solicitante',
  funcionarioVinculo: 'Funcionário de vínculo',
  ida: 'Ida',
  idade18Anos: 'Acompanhante não pode ter menos de 18 anos',
  idade60Anos: 'Acompanhante não pode ter mais de 60 anos',
  intervaloSolicitacaoAgendamento: 'Intervalo entre a data da solicitação e do agendamento',
  itinerario: 'Itinerário',
  justificativa: 'Justificativa',
  justificativaInvalida: 'A justificativa deve ter pelo menos 10 caracteres',
  justificativaTrocaAcompanhantes: 'Justificativa para troca de acompanhantes',
  justificativaTrocaTransporte: 'Justificativa para troca de transporte',
  km: 'Quilometragem',
  kmMaximo: 'A quilometragem máxima é de 99.999 Km',
  localizacao: 'Localização',
  matricula: 'Matrícula',
  motivacaoInvalida: 'O motivo deve ter pelo menos 10 caracteres válidos',
  motivoCancelamento: 'Motivo de cancelamento',
  motivoDevolucao: 'Motivo devolução',
  motivos: 'Motivo de cancelamento',
  motorista: 'Motorista',
  motoristas: 'Motoristas',
  movimentacao: 'Movimentação',
  movimentacoes: 'Movimentações',
  msgErroAdicionarFatura: 'Não foi possível adicionar fatura ao processo.',
  msgErroAnexarComprovante: 'Não foi possível anexar o comprovante de passagem do processo.',
  msgErroAprovarProcesso: 'Não foi possível aprovar a solicitação de passagem.',
  msgErroArquivarProcesso: 'Não foi possível arquivar a solicitação de passagem.',
  msgErroCancelarProcesso: 'Não foi possível cancelar a solicitação de passagem.',
  msgErroCriarEspelho: 'Não foi possível criar o processo espelho.',
  msgErroDevolverProcesso: 'Não foi possível devolver a solicitação de passagem.',
  msgErroEncaminharCompra: 'Não foi possível encaminhar a solicitação para compra de passagem.',
  msgErroEncaminharRegulador: 'Não foi possível encaminhar o processo ao regulador.',
  msgErroEnviarDossie: 'Não foi possível salvar o dossiê do paciente ao processo.',
  msgErroEscolherMotorista: 'Não foi possível reabrir a solicitação de passagem.',
  msgErroReabrirProcesso: 'Não foi possível reabrir a solicitação de passagem.',
  msgErroVoltarProcesso: 'Não foi possível voltar a solicitação de passagem.',
  msgSalvar: 'Solicitações de novos créditos e passagens somente serão concedidos mediante comprovação à Macrorregional/Coordenação/Supervisão Regional de Saúde de comparecimento do agendamento anterior, paciente com débito de algum documento ou comprovante solicitado não viajará pelo programa.',
  msgVincularProcesso: 'O processo que você está encaminhando é do tipo "Volta". Deseja vincular um processo de ida?',
  nomeAeroporto: 'Nome do aeroporto',
  nomePaciente: 'Nome do paciente',
  numero: 'Nº do TFD',
  numeroAcompanhantes: 'Número de acompanhantes',
  numeroAcompanhantesObrigatorioMenosIdade: 'Pacientes com menos de 18 anos devem ter ao menos um acompanhante',
  numeroAutos: 'Número dos autos',
  numeroCasa: 'Número',
  numeroDiarias: 'Nº de ajuda de custo sem pernoite',
  numeroFatura: 'Número da fatura',
  numeroFaturaInvalido: 'Nº da fatura inválido',
  numeroInvalido: 'Número inválido',
  numeroPernoites: 'Nº de ajuda de custo com pernoite',
  numeroProcessoSGPE: 'Número do processo SGPE',
  numeroProcessoSGPEInvalido: 'Número do processo SGPE inválido',
  numeroTfd: 'Nº do pedido do processo de TFD',
  obrigatorioJustificativaTextual: 'É obrigatório adicionar pelo menos um anexo ou uma justificativa textual',
  origem: 'Origem',
  parcial: 'Parcial',
  parentesco: 'Relação com o paciente',
  pesquisaProcesso: 'Pesquisa de processos de vínculo',
  pontoReferencia: 'Ponto de referência',
  procedimentoSolicitado: 'Procedimento solicitado',
  processoVinculo: 'Processo de vínculo',
  prosseguirVinculo: 'Prosseguir ao processo de vínculo',
  quantidadePassagens: 'Quantidade de passagens',
  reabrir: 'Reabrir',
  recuperarAcompOriginal: 'Recuperar nº de acompanhantes original',
  recuperarTransporte: 'Recuperar tipo de transporte original',
  reembolso: 'Reembolso',
  regional: 'Regional',
  regionalSaude: 'Gerência regional de saúde',
  regulador: 'Regulador',
  requisicao: 'Requisição',
  requisicaoPassagem: 'Requisição da solicitação de passagem',
  responsavel: 'Responsável',
  salvarRascunho: 'Salvar rascunho',
  selecionarMedicoRegulador: 'Selecionar médico regulador',
  selecionarMotorista: 'Selecionar motorista',
  sequencial: 'Sequencial',
  solicitacaoDeTfdPassagem: 'Solicitação de TFD-Passagem - ',
  solicitacaoTfdPassagem: 'Solicitações Passagens',
  solicitacoesPassagens: 'Solicitações de passagens',
  suspender: 'Suspender',
  telefoneInvalido: 'Telefone inválido',
  tfd: 'TFD',
  tipo: 'Tipo',
  tipoProcesso: 'Tipo de processo',
  tipoReembolso: 'Tipo de reembolso',
  tipoTransporte: 'Tipo de transporte',
  total: 'Total',
  transporte: 'Transporte',
  trechoTransporte: 'Trecho do transporte',
  trocaAcompanhantes: 'Troca de acompanhantes',
  ufDestino: 'UF de destino',
  ufInvalida: 'UF inválida',
  ufOrigem: 'UF de origem',
  unidadeExecutante: 'Unidade executante',
  valorMaximoPassagem: 'O valor máximo da passagem é de R$ 99.999,00',
  valorMaximoReembolso: 'O valor máximo do reembolso é de R$ 99.999,00',
  valorPassagem: 'Valor da passagem (em reais)',
  valorPassagens: 'Valor da(s) passagem(ns)',
  valorReembolso: 'Valor de reembolso',
  valorReembolsoRs: 'Valor do reembolso (em reais)',
  vincularIda: 'Vincular processo de ida',
  volta: 'Volta',
  voltarProcesso: 'Voltar processo'
};