"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AnexoIcon", {
  enumerable: true,
  get: function get() {
    return _Anexo["default"];
  }
});
Object.defineProperty(exports, "CameraDeVideoDesligadaIcon", {
  enumerable: true,
  get: function get() {
    return _CameraDeVideoDesligada["default"];
  }
});
Object.defineProperty(exports, "CameraDeVideoLigadaIcon", {
  enumerable: true,
  get: function get() {
    return _CameraDeVideoLigada["default"];
  }
});
Object.defineProperty(exports, "DesligarIcon", {
  enumerable: true,
  get: function get() {
    return _Desligar["default"];
  }
});
Object.defineProperty(exports, "EmojiIcon", {
  enumerable: true,
  get: function get() {
    return _Emoji["default"];
  }
});
Object.defineProperty(exports, "EncaminharAtendimentoIcon", {
  enumerable: true,
  get: function get() {
    return _EncaminharAtendimento["default"];
  }
});
Object.defineProperty(exports, "EnviarMensagemIcon", {
  enumerable: true,
  get: function get() {
    return _EnviarMensagem["default"];
  }
});
Object.defineProperty(exports, "HistoricoIcon", {
  enumerable: true,
  get: function get() {
    return _Historico["default"];
  }
});
Object.defineProperty(exports, "RegistroIcon", {
  enumerable: true,
  get: function get() {
    return _Registro["default"];
  }
});
Object.defineProperty(exports, "SetaDuplaIcon", {
  enumerable: true,
  get: function get() {
    return _SetaDupla["default"];
  }
});
Object.defineProperty(exports, "SttAdministrativo", {
  enumerable: true,
  get: function get() {
    return _administrativo["default"];
  }
});
Object.defineProperty(exports, "SttAgendaIcon", {
  enumerable: true,
  get: function get() {
    return _SttAgendaIcon["default"];
  }
});
Object.defineProperty(exports, "SttAgendamento", {
  enumerable: true,
  get: function get() {
    return _agendamento["default"];
  }
});
Object.defineProperty(exports, "SttAjudaIcon", {
  enumerable: true,
  get: function get() {
    return _SttAjudaIcon["default"];
  }
});
Object.defineProperty(exports, "SttAlofono", {
  enumerable: true,
  get: function get() {
    return _alofono["default"];
  }
});
Object.defineProperty(exports, "SttAlofonoIcon", {
  enumerable: true,
  get: function get() {
    return _SttAlofonoIcon["default"];
  }
});
Object.defineProperty(exports, "SttAnalise", {
  enumerable: true,
  get: function get() {
    return _analise["default"];
  }
});
Object.defineProperty(exports, "SttApac", {
  enumerable: true,
  get: function get() {
    return _apac["default"];
  }
});
Object.defineProperty(exports, "SttApacIcon", {
  enumerable: true,
  get: function get() {
    return _SttApacIcon["default"];
  }
});
Object.defineProperty(exports, "SttAreaAdmIcon", {
  enumerable: true,
  get: function get() {
    return _SttAreaAdmIcon["default"];
  }
});
Object.defineProperty(exports, "SttConectaIcon", {
  enumerable: true,
  get: function get() {
    return _SttConectaIcon["default"];
  }
});
Object.defineProperty(exports, "SttConsultorioVirtual", {
  enumerable: true,
  get: function get() {
    return _consultorioVirtual["default"];
  }
});
Object.defineProperty(exports, "SttCorreioIcon", {
  enumerable: true,
  get: function get() {
    return _SttCorreioIcon["default"];
  }
});
Object.defineProperty(exports, "SttCursosIcon", {
  enumerable: true,
  get: function get() {
    return _SttCursosIcon["default"];
  }
});
Object.defineProperty(exports, "SttDashboardIcon", {
  enumerable: true,
  get: function get() {
    return _SttDashboardIcon["default"];
  }
});
Object.defineProperty(exports, "SttEletrocardiografia", {
  enumerable: true,
  get: function get() {
    return _eletrocardiografia["default"];
  }
});
Object.defineProperty(exports, "SttEletroencefalografia", {
  enumerable: true,
  get: function get() {
    return _eletroencefalografia["default"];
  }
});
Object.defineProperty(exports, "SttEspirometria", {
  enumerable: true,
  get: function get() {
    return _espirometria["default"];
  }
});
Object.defineProperty(exports, "SttEstomatologia", {
  enumerable: true,
  get: function get() {
    return _estomatologia["default"];
  }
});
Object.defineProperty(exports, "SttExames", {
  enumerable: true,
  get: function get() {
    return _exames["default"];
  }
});
Object.defineProperty(exports, "SttExamesIcon", {
  enumerable: true,
  get: function get() {
    return _SttExamesIcon["default"];
  }
});
Object.defineProperty(exports, "SttGistelemedIcon", {
  enumerable: true,
  get: function get() {
    return _SttGistelemedIcon["default"];
  }
});
Object.defineProperty(exports, "SttHelpDeskIcon", {
  enumerable: true,
  get: function get() {
    return _SttHelpDeskIcon["default"];
  }
});
Object.defineProperty(exports, "SttHelpdesk", {
  enumerable: true,
  get: function get() {
    return _helpdesk["default"];
  }
});
Object.defineProperty(exports, "SttIcone", {
  enumerable: true,
  get: function get() {
    return _SttIcone["default"];
  }
});
Object.defineProperty(exports, "SttItCidades", {
  enumerable: true,
  get: function get() {
    return _SttItCidades["default"];
  }
});
Object.defineProperty(exports, "SttItExames", {
  enumerable: true,
  get: function get() {
    return _SttItExames["default"];
  }
});
Object.defineProperty(exports, "SttItPontos", {
  enumerable: true,
  get: function get() {
    return _SttItPontos["default"];
  }
});
Object.defineProperty(exports, "SttItSolicitacoes", {
  enumerable: true,
  get: function get() {
    return _SttItSolicitacoes["default"];
  }
});
Object.defineProperty(exports, "SttLaudosIcon", {
  enumerable: true,
  get: function get() {
    return _SttLaudosIcon["default"];
  }
});
Object.defineProperty(exports, "SttMensagens", {
  enumerable: true,
  get: function get() {
    return _mensagens["default"];
  }
});
Object.defineProperty(exports, "SttMeusExames", {
  enumerable: true,
  get: function get() {
    return _meusExames["default"];
  }
});
Object.defineProperty(exports, "SttMonitoracaoEventos", {
  enumerable: true,
  get: function get() {
    return _SttMonitoracaoEventos["default"];
  }
});
Object.defineProperty(exports, "SttOpExamesIcon", {
  enumerable: true,
  get: function get() {
    return _SttOpExamesIcon["default"];
  }
});
Object.defineProperty(exports, "SttOxigenoterapiaIcon", {
  enumerable: true,
  get: function get() {
    return _SttOxigenoterapiaIcon["default"];
  }
});
Object.defineProperty(exports, "SttPolissonografia", {
  enumerable: true,
  get: function get() {
    return _polissonografia["default"];
  }
});
Object.defineProperty(exports, "SttRegistroPacIcon", {
  enumerable: true,
  get: function get() {
    return _SttRegistroPacIcon["default"];
  }
});
Object.defineProperty(exports, "SttTabelaDados", {
  enumerable: true,
  get: function get() {
    return _SttTabelaDados["default"];
  }
});
Object.defineProperty(exports, "SttTeleconsultorias", {
  enumerable: true,
  get: function get() {
    return _teleconsultorias["default"];
  }
});
Object.defineProperty(exports, "SttTeleconsultoriasIcon", {
  enumerable: true,
  get: function get() {
    return _SttTeleconsultoriasIcon["default"];
  }
});
Object.defineProperty(exports, "SttTeledermatologia", {
  enumerable: true,
  get: function get() {
    return _teledermatologia["default"];
  }
});
Object.defineProperty(exports, "SttTeleeducacao", {
  enumerable: true,
  get: function get() {
    return _teleeducacao["default"];
  }
});
Object.defineProperty(exports, "SttTeleeducacaoIcon", {
  enumerable: true,
  get: function get() {
    return _SttTeleeducacaoIcon["default"];
  }
});
Object.defineProperty(exports, "SttTfd", {
  enumerable: true,
  get: function get() {
    return _tfd["default"];
  }
});
Object.defineProperty(exports, "SttTfdIcon", {
  enumerable: true,
  get: function get() {
    return _SttTfdIcon["default"];
  }
});
Object.defineProperty(exports, "SttTfdPassagens", {
  enumerable: true,
  get: function get() {
    return _tfdPassagens["default"];
  }
});
Object.defineProperty(exports, "SttTransplantesIcon", {
  enumerable: true,
  get: function get() {
    return _SttTransplantesIcon["default"];
  }
});
Object.defineProperty(exports, "SttWebconferenciasIcon", {
  enumerable: true,
  get: function get() {
    return _SttWebconferenciasIcon["default"];
  }
});
Object.defineProperty(exports, "XisIcon", {
  enumerable: true,
  get: function get() {
    return _Xis["default"];
  }
});
var _administrativo = _interopRequireDefault(require("./modulos/administrativo"));
var _meusExames = _interopRequireDefault(require("./modulos/meus-exames"));
var _agendamento = _interopRequireDefault(require("./modulos/agendamento"));
var _alofono = _interopRequireDefault(require("./modulos/alofono"));
var _analise = _interopRequireDefault(require("./modulos/analise"));
var _apac = _interopRequireDefault(require("./modulos/apac"));
var _consultorioVirtual = _interopRequireDefault(require("./modulos/consultorio-virtual"));
var _eletrocardiografia = _interopRequireDefault(require("./modulos/eletrocardiografia"));
var _eletroencefalografia = _interopRequireDefault(require("./modulos/eletroencefalografia"));
var _espirometria = _interopRequireDefault(require("./modulos/espirometria"));
var _estomatologia = _interopRequireDefault(require("./modulos/estomatologia"));
var _exames = _interopRequireDefault(require("./modulos/exames"));
var _helpdesk = _interopRequireDefault(require("./modulos/helpdesk"));
var _mensagens = _interopRequireDefault(require("./modulos/mensagens"));
var _polissonografia = _interopRequireDefault(require("./modulos/polissonografia"));
var _teleconsultorias = _interopRequireDefault(require("./modulos/teleconsultorias"));
var _teledermatologia = _interopRequireDefault(require("./modulos/teledermatologia"));
var _teleeducacao = _interopRequireDefault(require("./modulos/teleeducacao"));
var _tfd = _interopRequireDefault(require("./modulos/tfd"));
var _tfdPassagens = _interopRequireDefault(require("./modulos/tfd-passagens"));
var _SttAgendaIcon = _interopRequireDefault(require("./SttAgendaIcon"));
var _SttAjudaIcon = _interopRequireDefault(require("./SttAjudaIcon"));
var _SttAlofonoIcon = _interopRequireDefault(require("./SttAlofonoIcon"));
var _SttApacIcon = _interopRequireDefault(require("./SttApacIcon"));
var _SttAreaAdmIcon = _interopRequireDefault(require("./SttAreaAdmIcon"));
var _SttConectaIcon = _interopRequireDefault(require("./SttConectaIcon"));
var _SttCorreioIcon = _interopRequireDefault(require("./SttCorreioIcon"));
var _SttCursosIcon = _interopRequireDefault(require("./SttCursosIcon"));
var _SttDashboardIcon = _interopRequireDefault(require("./SttDashboardIcon"));
var _SttExamesIcon = _interopRequireDefault(require("./SttExamesIcon"));
var _SttGistelemedIcon = _interopRequireDefault(require("./SttGistelemedIcon"));
var _SttHelpDeskIcon = _interopRequireDefault(require("./SttHelpDeskIcon"));
var _SttIcone = _interopRequireDefault(require("./SttIcone"));
var _SttItCidades = _interopRequireDefault(require("./SttItCidades"));
var _SttItExames = _interopRequireDefault(require("./SttItExames"));
var _SttItPontos = _interopRequireDefault(require("./SttItPontos"));
var _SttItSolicitacoes = _interopRequireDefault(require("./SttItSolicitacoes"));
var _SttLaudosIcon = _interopRequireDefault(require("./SttLaudosIcon"));
var _SttMonitoracaoEventos = _interopRequireDefault(require("./SttMonitoracaoEventos"));
var _SttOpExamesIcon = _interopRequireDefault(require("./SttOpExamesIcon"));
var _SttOxigenoterapiaIcon = _interopRequireDefault(require("./SttOxigenoterapiaIcon"));
var _SttRegistroPacIcon = _interopRequireDefault(require("./SttRegistroPacIcon"));
var _SttTabelaDados = _interopRequireDefault(require("./SttTabelaDados"));
var _SttTeleconsultoriasIcon = _interopRequireDefault(require("./SttTeleconsultoriasIcon"));
var _SttTeleeducacaoIcon = _interopRequireDefault(require("./SttTeleeducacaoIcon"));
var _SttTfdIcon = _interopRequireDefault(require("./SttTfdIcon"));
var _SttTransplantesIcon = _interopRequireDefault(require("./SttTransplantesIcon"));
var _SttWebconferenciasIcon = _interopRequireDefault(require("./SttWebconferenciasIcon"));
var _Anexo = _interopRequireDefault(require("./atendimento/Anexo"));
var _CameraDeVideoDesligada = _interopRequireDefault(require("./atendimento/CameraDeVideoDesligada"));
var _CameraDeVideoLigada = _interopRequireDefault(require("./atendimento/CameraDeVideoLigada"));
var _EncaminharAtendimento = _interopRequireDefault(require("./atendimento/EncaminharAtendimento"));
var _EnviarMensagem = _interopRequireDefault(require("./atendimento/EnviarMensagem"));
var _Historico = _interopRequireDefault(require("./atendimento/Historico"));
var _SetaDupla = _interopRequireDefault(require("./atendimento/SetaDupla"));
var _Desligar = _interopRequireDefault(require("./atendimento/Desligar"));
var _Emoji = _interopRequireDefault(require("./atendimento/Emoji"));
var _Registro = _interopRequireDefault(require("./atendimento/Registro"));
var _Xis = _interopRequireDefault(require("./atendimento/Xis"));
function _interopRequireDefault(e) {
  return e && e.__esModule ? e : {
    "default": e
  };
}