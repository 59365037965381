import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttButton, SttModal, SttInput, SttAutocomplete } from '@stt-componentes/core';
import { Container } from '@material-ui/core';
import { SttTranslateHook } from '@stt-componentes/core';
import { pesquisaSignal } from 'src/signals/tabelaDados/tabelaDadosSignal';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        textTransform: 'none',
    },
    modal: {
        width: '60vw',
        height: 'calc(90vw / 1.414)',
        maxWidth: 'none',
        margin: 'auto',

        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
        },
    },
    pdfContainer: {
        width: '90%',
        height: '80vh',
        display: 'flex',
        marginLeft: 0,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '50vh',
        },
    },
    pdfIframe: {
        width: '100%',
        height: '100%',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100%',
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    conteudoModal: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: '10px',
        },
    },
    opcoesCustomizacao:{
        marginTop: '10px',
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}));

const estilos = ['Padrão', 'STT'];

function ModalExibicaoPdf({ open, onClose, pdfUrl, onExport, handleStyleChange}) {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const handleEstiloChange = (event, value) => {
        const estiloSelecionado = value || 'Padrão';
        handleStyleChange(estiloSelecionado);
    };

    return (
        <SttModal
            classes={{ paper: classes.modal }}
            title={strings.Comuns.visualizarRelatorio}
            open={open}
            onClose={onClose}
            footer={
                <div className={classes.footer}>                    
                    <SttButton variant="contained" color="primary" className={classes.button} onClick={onExport}>
                        {strings.Comuns.publicar}
                    </SttButton>
                    <SttButton variant="contained" color="primary" onClick={onClose} className={classes.button}>
                        {strings.Comuns.fechar}
                    </SttButton>
                </div>
            }
        >
            <div className={classes.conteudoModal}>
                <Container className={classes.pdfContainer}>
                    {pdfUrl ? (
                        <iframe src={pdfUrl} className={classes.pdfIframe} title="Visualizar PDF" />
                    ) : (
                        <p>URL inválida ou carregando...</p>
                    )}
                </Container>
                <div className={classes.opcoesCustomizacao}>
                    <SttInput 
                        inputProps={{
                            maxLength: 256,
                        }}
                        label={'Titulo'}
                        defaultValue={pesquisaSignal.value.titulo}   
                        onChange={(e) => {
                            const novoTitulo = e.target.value;
                            pesquisaSignal.value.titulo = novoTitulo ||  pesquisaSignal.value.titulo
                        }}
                    />
                    <SttAutocomplete
                        inputprops={{
                            label: 'Estilo',
                        }}
                        disabled
                        options={estilos}
                        defaultValue={'Padrão'}
                        onChange={handleEstiloChange}
                    />
                </div>
            </div>
        </SttModal>
    );
}

export default ModalExibicaoPdf;
