module.exports = {
  abordagemRealizada: 'Abordagem realizada',
  acessos: 'Acessos',
  adendoFormulario: 'Adendo de formulário',
  adicionarReplica: 'Adicionar Réplica',
  adicionarTreplica: 'Adicionar Tréplica',
  agenda: 'Agenda',
  aguardandoAprovacao: 'Aguardando aprovação',
  aguardandoComplementoResposta: 'AGUARDANDO COMPLEMENTO',
  aguardandoRegulacao: 'Aguardando regulação',
  aguardandoResposta: 'Aguardando resposta',
  aguardandoTreplica: 'AGUARDANDO TRÉPLICA',
  alteracaoNervos: 'Alteração dos nervos sensitivos',
  alteracaoResposta: 'Alterar dados da resposta',
  alterarEncaminhamento: 'Alterar encaminhamento',
  alterarResposta: 'Alterar resposta',
  alterarSolicitacao: 'Alterar dados da solicitação',
  alterarTeleconsultor: 'Alterar teleconsultor',
  analiseProblema: 'Análise do problema',
  anexosSolicitacao: 'Anexos da solicitação',
  aPartirDuvidaSugiro: 'A partir da sua dúvida, sugiro',
  apoioTutoria: 'Nota técnica elaborada com apoio de tutoria?',
  apresentacao: 'Apresentação',
  aprovar: 'Aprovar resposta',
  area: 'Área',
  areaSolicitante: 'Área do solicitante',
  areaTeleconsultor: 'Área do teleconsultor',
  assitenciaJudiciaria: 'Assistência judiciária gratuita (AJG)',
  assunto: 'Assunto',
  atendimento: 'A teleconsultoria resolveu sua dúvida',
  atendimentoPaciente: 'A solicitação de teleconsultoria está ligada ao atendimento de um paciente específico',
  atributosAps: 'Contribuições para os profissionais na APS',
  autorRepresentado: 'Autor está representado/assistido',
  avaliacao: 'Avaliação',
  avaliacaoRespostas: 'Avaliação de respostas',
  avaliada: 'Avaliada',
  avaliar: 'Avaliar teleconsultoria',
  avaliouTudo: 'Parabéns, você avaliou todas as suas teleconsultorias. Sua participação está sendo muito importante para melhorarmos a qualidade do sistema. Muito obrigado.',
  avisoAvaliacao: 'Lembre-se de avaliar a teleconsultoria após a visualização da resposta. Sua avaliação é de extrema importância para melhorarmos a qualidade do nosso sistema.',
  avisoDevolucaoSolicitante: `Utilize este botão sempre que necessitar de mais informações sobre o caso apresentado. ATENÇÃO! Lembramos que se o motivo da devolução for o encaminhamento indevido, deve-se fazer a devolução da teleconsultoria através do botão "Devolver para telerregulação".`,
  avisoRestricaoGenero: genero => `O envio de teleconsultorias para esta Rede não é permitido para pacientes do gênero "${genero}".`,
  avisoRestricaoInstituicao: 'O envio de teleconsultorias para esta Rede não é permitido para a Instituição/Equipe de Saúde que você selecionou.',
  avisoSemCarga: 'Existe um ou mais teleconsultores sem distribuição cadastrada. Sendo assim, este(s) teleconsultor(es) pode(m) não receber teleconsultorias desta rede de forma automática.',
  baciloscopia: 'Baciloscopia',
  beneficioResultadoEsperado: 'Benefício/efeito/resultado esperado',
  botaoDesabilitado: 'O botão de “Enviar Dúvida” deve ficar desabilitado ao selecionar esta rede?',
  breveResumoCasoConcreto: 'Breve resumo do caso concreto',
  buscarInsumos: 'Buscar insumos',
  buscarMedicamentos: 'Buscar medicamentos',
  buscarNutricoes: 'Buscar nutrição',
  buscarPaciente: 'Buscar paciente',
  buscarProcedimentoCbhpm: 'Buscar procedimentos CBHPM',
  buscarProcedimentoSigtap: 'Buscar procedimentos SIGTAP',
  cabecalho: 'Cabeçalho',
  cadastroAnexos: 'Cadastro de anexos',
  cadastroEspecificacao: 'Cadastro de especificações',
  cadastroInsumo: 'Cadastro de Insumos',
  cadastroMedicamento: 'Cadastro de medicamento',
  cadastroMensagens: 'Cadastro de mensagens',
  cadastroMotivosInvalidacao: "Cadastro de motivos de invalidação",
  cadastroNutricao: 'Cadastro de Nutrição',
  cadastroRedes: 'Cadastro de redes',
  cadastroRegiaoTeleconsultoria: 'Cadastro de redes',
  cadastroRestricaoGenero: 'Restrição de gênero',
  cadastroRestricaoIdade: 'Restrição de idade',
  cadastroRestricaoInstituicao: 'Restrição de CNES',
  cancelado: 'Cancelado',
  cargaHoraria: 'Distribuição',
  categoria: 'Categoria',
  cbhpm: 'CBHPM',
  cboOcupacaoAdicionados: 'CBOs ocupação adicionados',
  Cid10: {},
  classificacao: 'Classificação',
  classificacaoOperacional: 'Classificação operacional',
  classificacaoRisco: 'Classificação de risco',
  classificada: 'Classificada?',
  clinicaFamiliar: 'Clínica familiar',
  clinicaIndividual: 'Clínica individual',
  codigoInvalido: 'Código invalido',
  comorbidades: 'Histórico de Saúde',
  complementacao: 'Complementação',
  complementacoes: 'Complementações',
  complementacoesConitec: 'Complementação da recomendação',
  complementoDuracaoTratamento: 'Complemento duração do tratamento',
  complementoFormaFarmaceutica: 'Complemento forma farmacêutica',
  complementoIndicacao: 'Complemento da indicação de conformidade',
  complementoManejoPrevio: 'Complemento manejo prévio',
  complementoOncologico: 'Complemento oncológico',
  complementoParentesco: 'Complemento grau de parentesco',
  complementoRespostaTele: 'Complemento de Resposta de Teleconsultoria',
  complementoTempoTratamento: 'Complemento tempo de tratamento',
  conclusao: 'Conclusão',
  configuracoes: 'Configurações',
  confirmarAlteracaoInsumo: 'Você deseja realmente alterar o insumo?',
  confirmarAlteracaoMedicamento: 'Deseja realmente alterar o medicamento?',
  confirmarAlteracaoNutricao: 'Deseja realmente alterar esta nutrição?',
  confirmarAlteracaoResposta: 'Você tem certeza que deseja alterar a resposta desta teleconsultoria?',
  confirmarAlteracaoRespostaJudicial: 'Você confirma a alteração dos dados da resposta?',
  confirmarAlteracaoSolicitacaoJudicial: 'Você confirma a alteração dos dados da solicitação?',
  confirmarAprovacao: 'Você confirma a aprovação da resposta desta teleconsultoria?',
  confirmarAvaliacao: 'Você tem certeza que deseja avaliar a resposta desta teleconsultoria?',
  confirmarDevolverSolicitante: 'Você deseja realmente devolver esta teleconsultoria para o solicitante?',
  confirmarDevolverTelerregulacao: 'Você deseja realmente devolver esta teleconsultoria para telerregulação?',
  confirmarEncaminhamentoSecundario: 'Você confirma o encaminhamento da teleconsultoria para este(s) teleconsultor(es) secundário(s)?',
  confirmarEnvioAgenda: 'Você deseja realmente salvar os dados desta agenda personalizada?',
  confirmarEnvioVinculoTeleconsultor: 'Você deseja realmente salvar os dados de vínculo desse(s) teleconsultor(es) nesta rede?',
  confirmarEspecificacaoTeleconsultoria: 'Você deseja realmente salvar os dados desta especificação de teleconsultoria?',
  confirmarExclusaoDefinicao: 'Você deseja realmente excluir estas definições de CBO?',
  confirmarExclusaoMensagem: 'Você deseja realmente excluir esta mensagem?',
  confirmarExclusaoRestricaoGenero: 'Você deseja realmente excluir esta restrição de gênero do paciente?',
  confirmarExclusaoRestricaoIdade: 'Você deseja realmente excluir esta restrição de idade do paciente?',
  confirmarFinalizacaoAdministrativa: 'Você deseja realmente finalizar esta teleconsultoria administrativamente?',
  confirmarInsumoTeleconsultoria: 'Você deseja realmente salvar um novo insumo?',
  confirmarInvalidar: 'Você deseja realmente invalidar esta teleconsultoria?',
  confirmarMedicamentoTeleconsultoria: 'Deseja realmente cadastrar um novo medicamento?',
  confirmarMotivosInvalidacao: 'Você deseja realmente salvar este motivo de invalidação?',
  confirmarNutricaoTeleconsultoria: 'Deseja realmente cadastrar uma nova nutrição?',
  confirmarRedeTeleconsultoria: 'Você deseja realmente salvar os dados desta rede de teleconsultoria?',
  confirmarRegulacaoManual: 'Você confirma o encaminhamento da teleconsultoria para este teleconsultor?',
  confirmarRegulacaoMultipla: 'Você tem certeza que deseja encaminhar a(s) teleconsultoria(s) selecionada(s) para este teleconsultor?',
  confirmarRegulador: 'Você deseja realmente salvar as liberações para este regulador?',
  confirmarReplica: 'Você tem certeza que deseja enviar a réplica para a resposta desta teleconsultoria?',
  confirmarResposta: 'Você tem certeza que deseja responder a teleconsultoria? Após o envio da teleconsultoria não é mais permitida a edição da resposta.',
  confirmarRespostaSecundaria: 'Você tem certeza que deseja enviar o complemento de resposta para a teleconsultoria? Após o envio da teleconsultoria não é mais permitida a edição da resposta.',
  confirmarSalvarAnexos: 'Você deseja realmente salvar os anexos vinculados a esta rede de teleconsultoria?',
  confirmarSalvarLiberacao: 'Você deseja realmente salvar os dados desta liberação de CBO?',
  confirmarSalvarLiberacaoVisualizador: 'Você deseja realmente salvar os dados de liberação para este visualizador?',
  confirmarSalvarMensagem: 'Você deseja realmente salvar os dados desta mensagem?',
  confirmarSalvarRestricaoGenero: 'Você deseja realmente salvar os dados desta restrição de gênero do paciente?',
  confirmarSalvarRestricaoIdade: 'Você deseja realmente salvar os dados desta restrição de idade do paciente?',
  confirmarSalvarRestricaoInstituicao: 'Você deseja realmente salvar os dados desta restrição de CNES?',
  confirmarSolicitante: 'Você deseja realmente salvar as liberações para este(s) solicitante(s)?',
  confirmarTreplica: 'Você tem certeza que deseja enviar a tréplica para a resposta desta teleconsultoria?',
  confirmarTrocaTeleconsultor: 'Você tem certeza que deseja alterar o teleconsultor desta teleconsultoria?',
  confirmarTrocaTipoAgenda: 'Você deseja realmente alterar o tipo de agenda desta rede de teleconsultoria? Fazer esta alteração implica na deleção dos registros de agenda existentes.',
  conformacaoFamiliar: 'Conformação familiar',
  conteudoIgual: 'Já existe campo(s) textual(is) com o mesmo conteúdo. Por favor, verifique os dados preenchidos.',
  criticasSugestoes: 'Críticas ou sugestões',
  custosTecnologias: 'Custos das tecnologias',
  dadosDevolucao: 'Dados da devolução',
  dadosInvalidacao: 'Dados da invalidação',
  dadosPaciente: 'Dados do paciente',
  dadosProcesso: 'Dados do processo',
  dadosSolicitacao: 'Dados da solicitação',
  dadosSolicitante: 'Dados do solicitante',
  dataAtendimento: 'Data do atendimento',
  dataInclusao: 'Data de inclusão no sistema',
  dataResposta: 'Data da resposta',
  dataSolicitacao: 'Data da solicitação',
  definicaoCbo: 'Definição de CBO',
  descricaoExtensa: 'Descrição extensa',
  descricaoResumida: 'Descrição resumida',
  detalhamento: 'Detalhamento',
  detalhamentoCiapCapitulo: 'Detalhamento - CIAP-2 Capítulo',
  detalhamentoCiapConceito: 'Detalhamento - CIAP-2 Conceito',
  devolucao: 'Devolução',
  devolvendoTele: 'Devolvendo teleconsultoria...',
  devolverSolicitante: 'Devolver para o solicitante',
  devolverTelerregulacao: 'Devolver para telerregulação',
  devolvida: 'Devolvida?',
  devolvidoExames: 'Devolvido por falta de exames',
  devolvidoLaudo: 'Devolvido por falta de laudo',
  devolvidoOutros: 'Devolvido por falta de outros',
  devolvidoReceita: 'Devolvido por falta de receita',
  diagnostico: 'Diagnóstico',
  diagnosticoPrincipal: 'Diagnóstico principal',
  dificuldadePercebida: 'Dificuldade percebida - aspecto principal da dúvida do profissional solicitante',
  discussao: 'Discussão',
  disponivelSus: 'O produto está inserido no SUS?',
  disponivelSusMedicamento: 'O medicamento está disponível no SUS para situação clínica do demandante?',
  doseDia: 'Dose por dia (mg/dia)',
  duracaoTratamento: 'Duração do tratamento',
  duvida: 'Dúvida',
  duvidaEsclarecida: 'Sua dúvida foi esclarecida',
  duvidaPrincipal: 'Dúvida principal',
  editarResposta: 'Editar resposta',
  editarSolictacao: 'Editar solicitação',
  educacaoPermanente: 'Educação permanente',
  emAbertoNumeroTotal: numTotal => `Em aberto - ${numTotal} (número total)`,
  encaminhadoSegundaOpiniao: 'Esta teleconsultoria foi encaminhada para uma segunda opinião',
  encaminhamento: 'Encaminhamento',
  encaminhamentoCirurgia: 'Encaminhamento para cirurgia',
  encaminhamentosCaso: 'Encaminhamentos (Orientações, Sugestões, Encaminhamentos dados ao caso)',
  encaminhar: 'Encaminhar',
  encaminharTeleconsultor: 'Encaminhar para teleconsultor',
  encaminharTeleconsultorSecundario: 'Encaminhar para teleconsultores secundários',
  enviarDuvida: 'Enviar dúvida',
  episodioReacional: 'Episódio reacional',
  erroDescricao: 'A descrição deve ter pelo menos 5 caracteres válidos',
  erroDescricaoEspecificao: 'A descrição deve ter pelo menos 5 caracteres válidos',
  erroDescricaoMotivoInvalidacao: 'A descrição deve ter pelo menos 5 caracteres válidos',
  erroDevolverTeleconsultoria: 'Não foi possível devolver esta teleconsultoria para telerregulação.',
  erroIdadeMaxima: 'A idade máxima é de 120 anos',
  erroIdadeMinima: 'A idade mínima é de 1 ano',
  erroIndiceBaciloscopicoValor: 'O valor do índice baciloscópico deve ser entre 0 e 6.',
  erroIniciarSolicitacao: 'Não foi possível inicializar a solicitação de teleconsultorias. Tente novamente mais tarde.',
  erroInvalidarTeleconsultoria: 'Não foi possível invalidar esta teleconsultoria.',
  erroJaRespondida: 'Verificamos que já existe um registro válido de resposta para esta teleconsuloria. Por favor, verifique se você não está usando o sistema em outra aba/navegador. Caso o problema persista, entre em contato com o suporte.',
  erroNome100Caracteres: 'Nome só pode ter até 100 caracteres',
  erroNome255Caracteres: 'Nome pode conter até 255 caracteres',
  erroNumeroOab: 'Número da OAB deve ter 6 dígitos',
  erroOrdenacao: 'A ordenação deve possuir até 2 dígitos sendo mínimo 1 e máximo 99',
  erroReferenciaInvalida: 'Referência inválida',
  erroSalvarAutomaticamente: 'Não foi possível salvar a resposta automaticamente. Utilize a opção "Salvar rascunho" disponível na parte inferior da tela.',
  erroSalvarSolicitacaoAutomaticamente: 'Não foi possível salvar a solicitação automaticamente. Utilize a opção "Salvar rascunho" disponível na parte inferior da tela.',
  erroSemCiap2: 'É obrigatório inserir ao menos um CIAP-2',
  erroSemCid10: 'É obrigatório inserir ao menos um CID-10',
  erroSemSigtap: 'É obrigatório inserir um prodecimento SIGTAP',
  erroSemTecnologia: 'É obrigatório inserir ao menos uma tecnologia.',
  esferaOrgao: 'Esfera / Órgão',
  especialidade: 'Especialidade',
  especialidadeArea: 'Especialidade ou Área',
  especificacao: 'Especificação',
  especificacoes: 'Especificações',
  esquemaTerapeutico: 'Esquema terapeutico',
  estadual: 'Estadual',
  evidencias: 'Evidências',
  evidenciasCientificas: 'Há evidências científicas?',
  evitacaoEncaminhamento: 'Se sua intenção inicial ERA encaminhar esse paciente - Houve evitação de encaminhamento',
  evitouEncaminhamento: 'A teleconsultoria evitou encaminhamento de paciente ao especialista',
  evitouEncaminhamentoPergunta: 'A teleconsultoria evitou encaminhamento de paciente ao especialista?',
  examesComplementares: 'Exames complementares',
  examesImagem: 'Resultados de exames laboratoriais ou de imagem',
  examesLaboratoriais: 'Resultado dos exames laboratoriais',
  excluindoDefinicaoCbo: 'Excluindo definições de CBO...',
  excluindoMensagem: 'Excluindo mensagem...',
  excluindoRestricaoGenero: 'Excluindo restrição de gênero do paciente...',
  excluindoRestricaoIdade: 'Excluindo restrição de idade do paciente...',
  exigeComplemento: 'Exige complemento?',
  existeGenerico: 'Existe genérico?',
  existeSimilar: 'Existe similar?',
  ficaVisivel: 'A rede deve ficar visível na listagem?',
  fichaRegistro: 'Ficha de registro',
  filtroInstituicoes: 'Filtro de instituições',
  finalizacaoAdministrativa: 'Finalização Administrativa',
  finalizacaoEnviada: 'Teleconsultoria finalizada administrativamente',
  finalizar: 'Finalizar',
  finalizarAdministrativamente: 'Finalizar teleconsultoria administrativamente',
  formaClinica: 'Forma clínica',
  formaFarmaceutica: 'Forma farmacêutica',
  formaOrganizacao: 'Forma de organização (SIGTAP)',
  generosAdicionados: 'Gêneros adicionados',
  grauIncapacidade: 'Grau de incapacidade',
  grauSatisfacao: 'Qual seu grau de satisfação com esta teleconsultoria',
  grauSatisfacaoPergunta: 'Qual seu grau de satisfação com esta teleconsultoria?',
  habitos: 'Hábitos',
  hanseniase: 'Hanseníase',
  historiaAtual: 'Relato do caso',
  historiaAtualDuvida: 'Relato do caso',
  historiaPregressa: 'História pregressa',
  historico: 'Histórico',
  indicacaoConformidade: 'Indicação em conformidade com a aprovada no registro?',
  indicacaoServico: 'Você indicaria os serviços do Telessaúde SC a outro profissional',
  indiceBaciloscopico: 'Índice baciloscópico',
  informacaoTeleRespondida: 'O apoio oferecido por meio da teleconsultoria contempla sugestões de manejo dadas pelo teleconsultor, com base em evidências científicas, a partir do detalhamento do caso/situação pelo profissional solicitante. A tomada de decisão junto ao paciente ou equipe caberá ao profissional.',
  inicioAtendimento: 'Data início do atendimento',
  inserirAlterarClassificacao: 'Inserir/Alterar classificação',
  instituicaoTeleconsultor: 'Instituição do teleconsultor principal',
  instituicoesAdicionadas: 'Instituições adicionadas',
  instrucaoAgendaForm: `<span>- Cadastra para qual(is) teleconsultor(es) uma teleconsultoria deve ser regulada de forma automática.</span>
                                                                <span>- Todo dia 25 de cada mês a agenda é copiada para o mês subsequente. A cópia é feita sempre pelos dias da semana, ou seja se um teleconsultor está cadastrado na segunda-feira a cópia é feita para a segunda-feira do próximo mês.</span>
                                                                <span>- Quando temos mais de um teleconsultor selecionado no mesmo dia a teleconsultoria é regulada de forma alternada entre os profissionais.</span>
                                                                <span>- Quando temos o cadastro da distribuição é feito a distribuição de acordo com este cadastro, quanto maior a distribuição mais teleconsultoria o teleconsultor recebe.</span>
                                                                <span>- Sempre que uma distribuição é cadastrada para um teleconsultor todos os demais também devem ter essa informação preenchida, caso contrário podem não receber as dúvidas de forma automática.</span>`,
  instrucaoAssunto: 'O campo assunto está vinculado à seleção de CID e CIAP. Assegure-se de que tenha selecionado a opção mais adequada.',
  instrucaoCadastroAnexosRede: `Cadastra anexos informativos a serem exibidos aos solicitantes no momento da solicitação e visualização das teleconsultorias.`,
  instrucaoCadastroEspecificacao: `<span>- Cadastra uma especificação de teleconsultoria que é usada para detalhar a especialidade da teleconsultoria sendo enviada.</span>
                                                                <span>- Ao inativar uma especificação, a mesma não fica mais visível para o solicitante.</span>`,
  instrucaoCadastroMensagem: `Cria mensagens informativas na tela para o solicitante no momento da seleção de uma rede. É possível definir ainda, se o botão de "Enviar Dúvida" deverá ser desabilitado, ou não, ao solicitar uma teleconsultoria.`,
  instrucaoCadastroMotivosInvalidacao: '- Cadastra um motivo de invalidação de teleconsultoria que é usado para invalidar teleconsultorias',
  instrucaoConfiguracao: 'As configurações estão desabilitadas até que as alterações de classificação e/ou especificação sejam salvas.',
  instrucaoDefinicaoCbo: `Ao definir o CBO para determinada rede de teleconsultoria, apenas os usuários com este CBO poderão solicitar a teleconsultoria. Se não houver CBO definido, todos os CBOs ficam liberados para solicitar a teleconsultoria dentro daquela rede.`,
  instrucaoInstituicaoFiltro: 'O campo abaixo serve somente como um filtro para pesquisa de instituições. Na inserção de uma restrição, vale o que está selecionado no quadro abaixo do filtro.',
  instrucaoInsumosPesquisa: '<span>- Cadastra um medicamento judicial do tipo "Insumo" que é usado para detalhar qual insumo oferecido judicialmente o paciente terá direito. </span>',
  instrucaoLiberacao: 'Libera solicitantes para enviarem teleconsultorias de classificações específicas.',
  instrucaoLiberacaoRegulador: 'Libera a visualização de um telerregulador para visualizar na listagem de teleconsultorias. O telerregulador só consegue visualizar as teleconsultorias das classificações e cidades cadastradas. Caso não haja nenhum cadastro o telerregulador não consegue visualizar nenhuma teleconsultoria para regulação.',
  instrucaoLiberacaoVisualizador: `<span>- Libera a visualização de um usuário com o perfil de visualizador para visualizar na listagem de teleconsultorias.</span>
                                                                <span>- O visualizador só consegue visualizar as teleconsultorias das cidades e redes de teleconsultorias selecionadas.</span>
                                                                <span>- Caso não haja nenhum cadastro o visualizador não consegue visualizar nenhuma teleconsultoria.</span>`,
  instrucaoReferencia: 'Utilize referências atualizadas e baseadas em evidências.',
  instrucaoRegiaoTeleconsultoriaPesquisa: `<span>- Cadastra uma rede de teleconsultoria que é usada para um conjunto de municípios aptos a solicitar teleconsultoria de um determinado fluxo.</span>
                                                                <span>- A partir do cadastro da rede, é possível configurá-la definindo a classificação, especificação, municípios, e vinculações e prazos, além das restrições e definição de quem poderá solicitar a teleconsultoria.</span>`,
  instrucaoRestricaoGenero: `A restrição de gênero impede que uma teleconsultoria com paciente vinculado seja solicitada, de acordo com a critério de gênero estabelecido para aquele fluxo.`,
  instrucaoRestricaoIdade: `A restrição de idade impede que uma teleconsultoria com paciente vinculado seja solicitada, de acordo com a critério de idade estabelecido para aquele fluxo.`,
  instrucaoRestricaoInstituicao: `A restrição de CNES impede que solicitantes registrados em equipes de saúde vinculadas a(s) esta(s) instição(ões) solicitantem este tipo de teleconsultoria.`,
  instrucaoVinculoTeleconsultor: `<span>- Cadastra para qual(is) teleconsultor(es) uma teleconsultoria deve ser regulada de forma automática.</span>
                                                                <span>- Quando temos mais de um teleconsultor selecionado a teleconsultoria é regulada de forma alternada entre os profissionais.</span>
                                                                <span>- Quando temos o cadastro da distribuição é feito a distribuição de acordo com este cadastro, quanto maior a distribuição mais teleconsultoria o teleconsultor recebe.</span>
                                                                <span>- Sempre que uma distribuição é cadastrada para um teleconsultor todos os demais também devem ter essa informação preenchida, caso contrário não receberão as dúvidas de forma automática.</span>`,
  instrucaoVinculoTeleconsultorCirurgia: `<span>- Cadastra para qual(is) teleconsultor(es) uma teleconsultoria deve ser regulada de forma automática.</span>
                                                                <span>- Quando temos mais de um teleconsultor selecionado a teleconsultoria é regulada de forma alternada entre os profissionais.</span>`,
  instrucoesCadastroNutricao: '- Cadastra um medicamento judicial do tipo "nutrição" que é usada para detalhar qual nutrição oferecida judicialmente o paciente terá direito.',
  instrucoesPesquisaMedicamento: 'Cadastra um SCCD - medicamento (Sistema de Contratos e Compra Direta) que é usado para detalhar qual medicamento oferecido judicialmente o paciente terá direito.',
  insumo: 'Insumo',
  insumoAdicionado: 'Insumo adicionado.',
  insumoAdicionadoAnteriormente: 'Este insumo já foi adicionado anteriormente.',
  insumos: 'Insumos',
  integral: 'Integral',
  integranteMaisMedicos: 'Integrante do mais médicos',
  integranteProvab: 'Integrante do PROVAB',
  intencaoEncaminhamento: 'Você considera que este caso necessita de encaminhamento à atenção especializada?',
  intencaoEncaminhar: 'Após a teleconsultoria, você tem intenção de encaminhar o paciente',
  intencaoEncaminharPergunta: 'Após a teleconsultoria, você tem intenção de encaminhar o paciente?',
  invalidandoTele: 'Invalidando teleconsultoria...',
  laboratorio: 'Laboratório',
  lesoesCutaneas: 'Lesões cutâneas',
  lida: 'Lida?',
  listaPresenca: 'Lista de Presença',
  local: 'Local',
  maisInformacoes: 'Mais informações',
  manejoAps: 'Manejo na APS',
  manejoPrevio: 'Manejo prévio na APS',
  manejoPrevioResposta: 'Sugere manejo na APS enquanto aguarda o encaminhamento?',
  manifestacao: 'Manifestação',
  manifestacaoTecnica: 'Manifestação técnica',
  marcaComercial: ' Marca comercial',
  marcasAprovadas: 'Marcas aprovadas',
  medicamento: 'Medicamento',
  medicamentoAdicionado: 'Medicamento adicionado.',
  medicamentoAdicionadoAnteriormente: 'Este medicamento já foi adicionado anteriormente.',
  medicamentos: 'Medicamentos',
  medicamentosRecursos: 'Medicamentos e outros recursos',
  meiosConfirmatorios: 'Meio(s) confirmatório(s) do(s) diagnóstico(s) já realizado(s)',
  mejud: 'Sistema de medicamentos judiciais',
  mejudInsumos: 'INSUMOS',
  mensagemAviso: 'Mensagem de aviso',
  mensagemErroBuscaTermo: 'Para pesquisar é obrigatório informar o termo a ser encontrado.',
  mensagemSemEquipe: `<p>Não é possível fazer uma solicitação de teleconsultoria neste momento, pois a sua lista de equipes/unidades de saúde está <b>vazia ou não consta(m) a(s) sua(s) equipe(s) de saúde atual(is).</b></p>
                                                                <p>Por favor, entre em contato com o Núcleo Telessaúde SC.</p>
                                                                <p>Telefone: (48) 3721-4448 / e-mail: telessaude@contato.ufsc.br</p>`,
  mensagemSemVinculoRede: '<p>Você não possui vínculo com nenhuma rede de teleconsultoria, logo não será possível exibir o quadro com os indicadores das respostas.</p>',
  mensagemSemVinculoRedeResponder: '<p>Você não possui vínculo com nenhuma rede de teleconsultoria, logo não será possível responder teleconsultorias.</p>',
  motivoEncaminhamento: 'Motivo de encaminhamento',
  motivoJudicilizacao: 'Motivos da judicialização',
  motivosInvalidacao: 'Motivos de invalidação',
  motivoTrocaTeleconsultor: 'Motivo da troca de teleconsultor',
  movimentacoes: 'Movimentações',
  mudancaConduta: 'Se sua intenção inicial NÃO ERA encaminhar esse paciente - Houve mudança nessa conduta',
  municipal: 'Municipal',
  naoAvaliadas: 'Não avaliadas',
  naoPorque: 'Não, porque: ',
  necessidadeInfoComplementar: 'Em caso de necessidade de informações complementares, qual? (Caso não solucionado que exige novo serviço)',
  nenhumRegistroAdicionado: 'Nenhum registro adicionado',
  nervosAfetados: 'Nervos afetados',
  nomeAdvogado: 'Nome do advogado',
  nomeRepresentante: 'Nome do representante civil',
  nomeTeleconsultor: 'Nome do teleconsultor',
  novaSolicitacao: 'Nova solicitação',
  numeroDosesIngeridas: 'Nº de doses ingeridas',
  numeroIntegrantes: 'Número de integrantes na família',
  numeroLesoes: 'Nº de lesões',
  numeroOab: 'Número OAB',
  numeroProcesso: 'Número do processo',
  numeroProcessoVinteCaracteres: 'O número do processo deve conter exatamente 20 caracteres.',
  numeroTeleconsultoria: 'Número da teleconsultoria',
  nutricao: 'Nutrição',
  nutricaoAdicionada: 'Nutrição adicionada.',
  nutricaoAdicionadaAnteriormente: 'Esta nutrição já foi adicionado anteriormente.',
  nutricoes: 'Nutrição',
  obrigatoriedadePaciente: 'Vinculação de paciente',
  observacaoPadrao: "Observação padrão",
  oncologico: 'Oncológico?',
  operador: 'Operador',
  ordenacao: 'Ordenação',
  orientacaoEspecifica: 'Orientação específica',
  orientacaoEspecificaPergunta: 'Qual a sua orientação específica?',
  orientacoesFinais: 'Orientações finais do Teleconsultor ao Profissional Solicitante (Explicações de como acessar outro serviço, quando necessário, e outras colocações do Teleconsultor ou agradecimentos)',
  orientacoesGerais: 'Orientações gerais (educativas, administrativas)',
  orientacoesManejo: 'Orientações de manejo',
  origemPrescricao: 'Origem da prescrição médica',
  outrasTecnologias: 'Outras tecnologias disponíveis',
  outroMotivoEncaminhamento: 'Outro motivo de encaminhamento',
  outrosProblemas: 'Outros problemas/comorbidades',
  pacienteEspecifico: 'Sua dúvida trata de um caso de paciente específico?',
  paraVisualizalas: ' para visualizá-la(s).',
  paraVisualizalos: ' para visualizá-lo(s).',
  parecer: 'Parecer',
  parentesco: 'Grau de parentesco',
  personalizada: 'Personalizada',
  placeholderApresentacao: 'Inserir a apresentação da tecnologia.',
  placeholderAtributosAps: 'Indicar aos profissionais na APS ações preventivas, evidências sobre os fatores de risco, orientações que auxiliem no processo de educação permanente e cumprimento dos atributos da APS. O teleconsultor pode salientar quais aspectos do caso clínico a equipe de saúde deve estar atenta para a continuidade do cuidado, o monitoramento e como evitar novos casos semelhantes à dúvida principal.',
  placeholderClassificacaoRisco: 'Indicar a priorização do atendimento de acordo com a situação e as evidências utilizadas para orientar a construção da resposta.',
  placeholderComorbidades: 'Descrever hábitos e comorbidades do paciente que possam influenciar suas condições de saúde, além de internações, cirurgias e tratamentos anteriores que sejam pertinentes ao caso.',
  placeholderComplementacao: 'Inserir esclarecimentos necessários sobre a recomendação da CONITEC.',
  placeholderComplementacao1: 'Espaço para embasar cientificamente as orientações acima, apresentando o porquê de tais indicações com informações complementares e embasadas na literatura sobre a situação/caso, conceitos, epidemiologia e etiologia; diagnóstico, condutas e prognóstico; contexto psicossocial.',
  placeholderComplementacao2: 'Espaço para detalhamento de ações, atividades e possibilidades de atuação para a situação específica, com base em evidências científicas, e/ou solicitação de mais informações.',
  placeholderComplementacaoRespostaSecundario: 'Complemente a resposta enviada pelo teleconsultor principal desta teleconsultoria.',
  placeholderComplemento: 'Inserir a indicação da bula, ou a via de administração, ou mesmo informações sobre a idade, demonstrando o motivo que não está de acordo com o registro sanitário da tecnologia.',
  placeholderComplementoOncologico: 'Informar se a compra é centralizada/Depende de cada CACON/UNACON.',
  placeholderConclusao: 'Inserir a conclusão em relação ao pleito, considerando as informações prestadas anteriormente na nota técnica, tais como: se medicamento tem indicação para o uso pleiteado; evidência científica e políticas públicas.',
  placeholderDiagnostico: 'Inserir a(s) doença(s) que acomete(m) a parte autora e portanto está solicitando os pleitos.',
  placeholderDuvidaPrincipal: 'A partir da descrição do caso apresentado, qual a sua dúvida?',
  placeholderEducacaoPerma: 'Apoio do teleconsultor para instigar o profissional a pensar além, para indicar material de estudo, fomentar o interesse e o olhar para outras questões relacionadas ao caso. Indica-se convidar o profissional a refletir sobre o tema de forma ampliada e convidar o solicitante a pensar o trabalho para estimular o envio de outras dúvidas.',
  placeholderEvidencias: 'Inserir evidências referentes às tecnologias/produtos solicitados.',
  placeholderExamesComplementares: 'Orientação quanto aos exames que o paciente deve realizar previamente à consulta com o especialista.',
  placeholderExamesImagem: 'Listar os resultados dos exames laboratoriais e de imagem que contribuam com a análise do caso.',
  placeholderExamesLaboratorias: 'Listar resultados de exames laboratoriais já realizados e que contribuam com a descrição do caso.',
  placeholderHabitos: 'Descrever hábitos do paciente que impactem na condição de saúde-doença em discussão. Ex.: tabagismo, alcoolismo, uso de drogas, prática de atividade física, sono etc.',
  placeholderHistoriaDuvida: 'Descrever o caso com clareza, apontando a história clínica atual de forma detalhada.',
  placeholderHistoriaPregressa: 'Informar situações de vida e saúde anteriores, cirurgias, tratamentos etc.',
  placeholderLaboratorio: 'Inserir os dados do labotatório que produz a tecnologia.',
  placeholderMaisInformacoes: 'Deve ser selecionada quando não for possível responder adequadamente porque faltaram informações básicas para a resposta completa e objetiva. Por exemplo: faltaram informações sobre exames a serem enviados em outra solicitação – essa opção aqui é a que deve ser marcada para indicar isso.',
  placeholderManejoAps: 'Descrever detalhadamente sobre o manejo proposto na APS de forma que o profissional solicitante consiga ofertar o cuidado adequado e oportuno.',
  placeholderManejoPrevio: 'Orientação sobre o manejo prévio na APS enquanto aguarda o encaminhamento ao especialista.',
  placeholderMarcaComercial: 'Inserir os dados da marca comercial fabricante da tecnologia.',
  placeholderMeiosConfirmatorios: 'Provas que corroboram diagnóstico, tais como prontuário ambulatorial; prontuário médico, exames laboratoriais; exames de imagem.',
  placeholderNomeAdvogado: 'Inserir o nome completo do advogado, no caso de ser defensoria, o nome completo do defensor responsável',
  placeholderNomeRepresentante: 'Inserir o nome completo do representante civil',
  placeholderOab: 'Inserir o número do registro da OAB do advogado/defensor solicitante',
  placeholderObservacao: 'Inserir informações adicionais. Exemplo: os dados foram calculados segundo dados da CMED publicados em: XX/XX/XXXX; e atualizada em: XX/XX/XXXX.',
  placeholderOutrasTecnologias: 'Inserir outras tecnologias que estão disponíveis no SUS para o caso concreto, inclusive informando sobre PCDT, caso exista.',
  placeholderPosologia: 'Inserir a posologia conforme o último receituário acostado nos autos, e referenciar o documento. Exemplo: um comprimido pela manhã.',
  placeholderReplica: 'Informe detalhadamente quais são suas dúvidas relacionadas a resposta enviada pelo teleconsultor.',
  placeholderRespostaDireta: 'Resposta específica à dúvida principal do solicitante, com clareza e objetividade.',
  placeholderResultados: 'Inserir benefícios, efeitos e resultados esperados das tecnologias solicitadas.',
  placeholderResumoCaso: ' Trazer um resumo do caso concreto, sempre referenciando os documentos acostados aos autos.',
  placeholderSugestao: 'Espaço para as sugestões no sentido de promover a resolução do problema.',
  placeholderTratamentosAndamento: 'Listar medicamentos e tratamentos alternativos em uso.',
  placeholderTreplica: 'Responda detalhadamente os questionamentos levantados na réplica pela solicitante.',
  placeholderVara: 'Inserir o nome completo da vara/serventia',
  placeholderVia: 'Via de administração que o medicamento será utilizado pela parte autora',
  planoSugerido: 'Plano sugerido',
  posologia: 'Posologia',
  potencialOpiniaoFormativa: 'Potencial Segunda Opinião Formativa (SOF)',
  prazoNormal: 'NO PRAZO',
  prazoRegulacao: 'Prazo para regulação',
  prazoReplica: 'Prazo para envio de réplicas (em horas)',
  prazoResponder: 'Prazos para responder as teleconsultorias!',
  prazoResposta: 'Prazo para resposta',
  prazosResposta: 'respostas no prazo',
  prazoVencendo: 'VENCENDO',
  prazoVencido: 'VENCIDO',
  prazoVencimento: 'Prazo para vencimento (em horas)',
  precoFabrica: 'Preço de fábrica (PF)',
  precoMaximo: 'O valor máximo aceito é de R$99.999.999,99',
  precoMaximoConsumidor: 'Preço máximo ao consumidor (PMC)',
  precoMaximoGoverno: 'Preço máximo de venda ao governo (PMVG)',
  precoMedioTratamento: 'Preço médio do tratamento',
  precoTratamento: 'Preço do tratamento',
  precoTratamentoAnual: 'Preço do tratamento anual',
  preencherFicha: 'Preencher ficha',
  previstoProtocolo: 'Previsto em Protocolo Clínico e Diretrizes Terapêuticas do Ministério da Saúde para a situação clínica do demandante?',
  preVisualizacaoImagem: 'A visualização prévia de protocolos clínicos só está disponível para imagens.',
  procedimento: 'Procedimento',
  procedimentoAdicionado: 'Procedimento adicionado.',
  procedimentoAdicionadoAnteriormente: 'Este procedimento já foi adicionado anteriormente.',
  procedimentosCbhpm: 'Procedimentos CBHPM',
  procedimentosSigtap: 'Procedimentos SIGTAP',
  processoTrabalho: 'Processo de trabalho',
  protocoloClinico: 'Visualização de protocolos clínicos',
  qualClassificacaoRisco: 'Qual a classificação de risco?',
  que: 'que',
  rascunhoResposta: `<p>Esta resposta de teleconsultoria é um <b>RASCUNHO</b>. Por favor, finalize o preenchimento do formulário de resposta.</p>`,
  rascunhoRespostaVisualizacao: `<p>Este complemento de resposta de teleconsultoria é um <b>RASCUNHO</b>.</p>`,
  recomendacaoConitec: 'Recomendações da CONITEC para a situação clínica do demandante',
  redeApoio: 'Rede de apoio',
  redes: 'Redes',
  redesAdicionadas: 'Redes adicionadas',
  redesTeleconsultoria: 'Redes de Teleconsultoria',
  redeTeleconsultoria: 'Rede de Teleconsultoria',
  referencia: 'Referência',
  referencias: 'Referências',
  referenciasEstrategias: 'Referências e estratégias adotadas para formular a resposta',
  regiao: 'Rede de teleconsultoria',
  registroAnvisa: 'Registro na ANVISA?',
  regulacao: 'Regulação',
  regulacaoMultipla: 'Regulação múltipla de teleconsultorias',
  regulacaoTele: 'Telerregulação de Teleconsultoria',
  regulador: 'Telerregulador',
  reguladores: 'Telerreguladores',
  regularTeleconsultoria: 'Regular Teleconsultoria(s)',
  relatoPaciente: 'Relatos do paciente',
  replica: 'Réplica',
  replicaEnviada: 'Réplica enviada com sucesso',
  replicaTreplica: 'Réplica e Tréplica',
  residente: 'Residente',
  resolubilidade: 'Resolubilidade da Teleconsultoria',
  resolveuDuvida: 'A teleconsultoria resolveu a sua dúvida?',
  responder: 'Responder',
  responderTele: 'Enviar resposta',
  responderTeleComplemento: 'Enviar complemento de resposta',
  respondidas: 'Respondidas',
  respondidasNumeroTotal: numTotal => `Respondidas - ${numTotal} (número total)`,
  responsavelLegal: 'Responsável legal',
  resposta: 'Resposta',
  respostaDireta: 'Resposta direta',
  respostasComplementares: 'Respostas complementares',
  respostasParaLer: 'Respostas para ler',
  respostaTele: 'Resposta de Teleconsultoria',
  restricaoAdicionadoAnteriormente: 'Este operador de restrição de idade já foi adicionado anteriormente.',
  restricaoCnes: 'Restrição de CNES',
  restricaoGenero: 'Restrição de gênero',
  restricoesIdade: 'Restrições de idade',
  restringirIdadePaciente: 'Restringir pacientes com idade',
  resultadoExames: 'Resultados dos exames realizados',
  resumoCaso: 'Resumo do caso',
  reus: 'Réus',
  salvandoRegulacao: 'Salvando telerregulação',
  salvandoResposta: 'Salvando resposta...',
  satisfacaoSolicitacao: 'Satisfação quanto à solicitação da teleconsultoria',
  satisfacaoTeleconsultoria: 'Grau de satisfação com a Teleconsultoria',
  selecioneTeleconsultor: 'Por favor, selecione ao menos um teleconsultor.',
  semTeleconsultoriasAguardandoResposta: ['Nenhuma teleconsultoria aguardando resposta', 'nesta rede.'],
  semTeleconsultoriasRespondidas: ['Nenhuma teleconsultoria respondida', 'nos últimos 4 meses nesta rede.'],
  sinaisSintomas: 'Sinais e sintomas',
  sinteseOrientacao: 'Síntese da Orientação - Discussão (Resumo da interação Teleconsultor/Profissional?)',
  situacaoRegistro: 'Situação do registro',
  solicicoesParaResponder: 'Solicitações para responder',
  solicitacaoEnviada: 'Solicitação enviada com sucesso.',
  solicitacaoExames: 'Solicitação de exames complementares',
  solicitacaoTele: 'Solicitação de Teleconsultoria',
  solicitacoesAbertas: 'Solicitações em aberto',
  solicitantes: 'Solicitantes',
  solicitantesAdicionados: 'Solicitantes adicionados',
  solicitoMaisInformacoes: 'Solicito mais informações para poder colaborar',
  statusTeleconsultoria: 'Status da teleconsultoria',
  sucessoExclusaoCbo: 'Definições de CBO excluídas com sucesso.',
  sucessoExclusaoMensagem: 'Mensagem excluída com sucesso.',
  sucessoExclusaoRestricaoGenero: 'Restrição de gênero do paciente excluída com sucesso.',
  sucessoExclusaoRestricaoIdade: 'Restrição de idade do paciente excluída com sucesso.',
  sugeriuEncaminhamento: 'O teleconsultor sugeriu encaminhamento ao especialista',
  sugeriuEncaminhamentoPergunta: 'O teleconsultor sugeriu encaminhamento ao especialista?',
  sugestao: 'Sugestão',
  sugestaoCritica: 'Críticas ou sugestões',
  sugiroCirurgia: 'Intervenção cirúrgica',
  sugiroEncaminhamento: 'Encaminhamento ao especialista',
  sugiroManejoAps: 'Manejo na APS',
  tecnologia: 'Tecnologia',
  tecnologias: 'Tecnologias',
  tecnologiasSolicitadas: 'Tecnologias solicitadas',
  tele0800: 'Teleconsultoria 0800',
  teleBaseadaCC: 'Teleconsultoria baseada em conduta clínica',
  teleCondutaClinica: 'Teleconsultoria de conduta clínica',
  teleconsultor: 'Teleconsultor',
  teleconsultorAdicionadoAnteriormente: 'Este teleconsultor já foi adicionado anteriormente.',
  teleconsultores: 'Teleconsultores',
  teleconsultoresAdicionados: 'Teleconsultores adicionados',
  teleconsultoresDisponiveis: 'Teleconsultores secundários disponíveis',
  teleconsultoresSecundarios: 'Teleconsultores secundários selecionados',
  teleconsultoria: 'Teleconsultoria',
  teleconsultoriaAprovada: 'Resposta de teleconsultoria aprovada com sucesso.',
  teleconsultoriaAutomatica: 'Esta é uma teleconsultoria gerada automaticamente pela realização de um exame de Teledermatologia que recebeu a classificação de risco AZUL, trata da sugestão de conduta clínica para o tratamento desta lesão, tal informação também está disponível no laudo do exame deste paciente.',
  teleconsultoriaAvaliada: 'Teleconsultoria avaliada com sucesso',
  teleconsultoriaAvaliadasBoa: 'avaliadas como boa/muita boa',
  teleconsultoriaComplementoRespondida: 'Complemento de resposta enviado com sucesso.',
  teleconsultoriaDevolvida: 'Esta teleconsultoria foi devolvida.',
  teleconsultoriaDevolvidaSucesso: 'Teleconsultoria devolvida com sucesso.',
  teleconsultoriaEncaminhadaSecundarios: 'Teleconsultoria encaminhada ao(s) teleconsultor(es) secundário(s) com sucesso.',
  teleconsultoriaInvalidada: 'Teleconsultoria invalidada com sucesso.',
  teleconsultoriaRecomendacaoManejo: 'respostas que recomendaram o manejo na APS',
  teleconsultoriaRegulada: 'Teleconsultoria encaminhada ao teleconsultor.',
  teleconsultoriaRespondida: 'Teleconsultoria respondida com sucesso.',
  teleconsultoriaRespondidasPrazo: 'segundo a pactuação da rede',
  teleconsultorias: 'Teleconsultorias',
  teleconsultoriasReguladas: 'Teleconsultoria(s) regulada(s) com sucesso',
  teleconsultorModificado: 'Teleconsultor modificado com sucesso',
  teleconsultorPrincipal: 'Teleconsultor principal',
  teleconsultorTreplica: 'Teleconsultor responsável pela tréplica',
  teleReguladaAutomaticamente: 'Teleconsultoria regulada automaticamente',
  telerregulacao: 'Telerregulação',
  teleSelecionada: 'Teleconsultoria selecionada',
  telesSelecionadas: 'Teleconsultorias selecionadas',
  tempoTratamento: 'Tempo de tratamento',
  tempoUso: 'Tempo de uso',
  terapeutica: 'Terapêutica',
  terminoAtendimento: 'Data término atendimento',
  textoConfirmarEnvio: `Estou ciente de que a teleconsultoria não atende a discussão de casos de urgência e emergência. As informações contidas nas respostas das teleconsultorias constituem-se em orientações de conduta, a partir da análise de um profissional especialista. Estou ciente de que cabe a mim, profissional de saúde, a inteira responsabilidade pela tomada de decisão e conduta em relação aos casos e situações apresentados.`,
  textoConfirmarRascunho: `Ao salvar o rascunho a sua resposta NÃO será encaminhada para o solicitante. Para isso, você precisa clicar em "Enviar Resposta".`,
  textoConfirmarRascunhoSecundario: `Ao salvar o rascunho a sua complementação de resposta NÃO será encaminhada para o solicitante. Para isso, você precisa clicar em "Enviar complemento de resposta".`,
  textoConfirmarRascunhoSolicitacao: `Ao salvar o rascunho a sua solicitação NÃO será encaminhada para um teleconsultor responder. Para isso, você precisa clicar em "Enviar Dúvida". Caso você salve o rascunho, o mesmo ficará disponível por oito dias para que volte, finalize e envie a teleconsultoria. Após esse prazo, o conteúdo será excluído do sistema.`,
  textoFinalResposta: 'Se a sua dúvida não foi atendida, solicite uma nova teleconsultoria e lembre-se de descrevê-la detalhadamente. Por favor, avalie esta resposta. Solicite nova teleconsultoria sempre que precisar de apoio em sua prática profissional.',
  tipo: 'Tipo',
  tipoAgenda: 'Tipo de Agenda',
  tipoDemanda: 'Tipo de demanda',
  tipoEpisodioReacional: 'Tipo de episódio reacional',
  tipoJuizado: 'Tipo de juizado',
  tipoRegulacao: 'Tipo de regulação',
  tipoRepresentacao: 'Tipo de representação',
  tipoResposta: 'Tipo de resposta',
  tipoTecnologia: 'Tipo de tecnologia',
  tipoTeleconsultoria: 'Tipo de teleconsultoria',
  totalAguardandoResposta: 'Total de teleconsultorias aguardando resposta.',
  totalRespondidas4Meses: 'Total de teleconsultorias respondidas nos últimos 4 meses.',
  tratamentoReacional: 'Tratamento reacional',
  tratamentosAndamento: 'Tratamentos em andamento',
  tratamentosAnteriores: 'Tratamentos anteriores',
  treplica: 'Tréplica',
  treplicaEnviada: 'Tréplica enviada com sucesso',
  trocaTeleconsultor: 'Troca de Teleconsultor',
  ufOab: 'UF OAB',
  ultimos6Meses: 'nos últimos 6 meses',
  unidadeCotacao: 'Unidade de cotação',
  unidadeFornecimento: 'Unidade de fornecimento',
  usoContinuo: 'Uso contínuo?',
  validacaoCodigo: 'Validacao-codigo',
  varaServentia: 'Vara / Serventia',
  verificamosRedeAnexo: 'Acesse AQUI o protocolo clínico sobre esta especialidade.',
  verificamosTelePacienteRespondida: 'Acesse AQUI o histórico de teleconsultorias já respondidas sobre este paciente.',
  viaAdministracao: 'Via de administração',
  visualizador: 'Visualizador',
  visualizadores: 'Visualizadores'
};