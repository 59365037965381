import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import PDFUtil from './PDFUtil';
const gerarDocJSPDF = function (tableElement) {
  let isFrontend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!tableElement) {
    throw new Error('Elemento da tabela não encontrado');
  }
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4'
  });
  const totalPagesExp = '{total_pages_count_string}';
  doc.setFont('helvetica');
  doc.setFontSize(9);
  doc.setDrawColor(50, 54, 57);
  const larguraPagina = doc.internal.pageSize.getWidth();
  const alturaPagina = doc.internal.pageSize.getHeight();
  const margem = 20;
  const footerHeight = 70;
  autoTable(doc, {
    html: tableElement,
    useCss: true,
    margin: {
      top: 160,
      right: margem,
      bottom: footerHeight + 40,
      left: margem
    },
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    didDrawPage: data => {
      if (isFrontend) {
        const blocoAltura = 70; // Ajustado para 70
        const blocoLargura = 70;
        const logoLargura = 100; // Largura ajustada para logo
        const textoPlaceholderLargura = 300; // Largura maior para texto
        const espacoEntre = 10;
        // Header Skeleton
        doc.setFillColor('#e4e4e4');
        doc.rect(margem, 20, 80, 80, 'F'); // Logo Esquerdo (afastado)
        doc.rect(larguraPagina / 2 - 180, 20, 360, 80, 'F'); // Título (centralizado)
        doc.rect(larguraPagina - margem - 80, 20, 80, 80, 'F'); // Logo Direito
        doc.rect(margem, 110, larguraPagina - 2 * margem, 30, 'F'); // Linha Subtítulo 1
        // Footer Skeleton (único bloco)
        const footerY = alturaPagina - footerHeight - 30; // Ajuste na posição do footer
        doc.rect(margem, footerY, larguraPagina - 2 * margem, blocoAltura, 'F');
      }
      // Rodapé
      doc.setFontSize(10);
      let textoRodape = 'Página ' + data.pageNumber;
      if (typeof doc.putTotalPages === 'function') {
        textoRodape = textoRodape + ' de ' + totalPagesExp;
      }
      doc.text(textoRodape, larguraPagina - margem - 60, alturaPagina - 10);
    }
  });
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  return doc;
};
const exportarFrontend = novoEstilo => {
  const tableElement = PDFUtil.gerarHTMLTabelaDinamica(novoEstilo);
  const doc = gerarDocJSPDF(tableElement, true);
  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  return pdfUrl;
};
const exportarBackend = htmlString => {
  const dom = new JSDOM(htmlString);
  global.window = dom.window;
  global.document = window.document;
  global.Node = window.Node;
  const tableElement = document.querySelector('table.pvtTable');
  const doc = gerarDocJSPDF(tableElement);
  return doc.output('arraybuffer');
};
export default {
  exportarFrontend,
  exportarBackend
};